import { render, staticRenderFns } from "./theme-4.vue?vue&type=template&id=689d2314"
import script from "./theme-4.vue?vue&type=script&lang=js"
export * from "./theme-4.vue?vue&type=script&lang=js"
import style0 from "./theme-4.vue?vue&type=style&index=0&id=689d2314&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsDownloadBanner: require('/var/app/components/Commons/DownloadBanner.vue').default,CommonsMaintenance: require('/var/app/components/Commons/Maintenance.vue').default,CommonsChangeLanguageLoading: require('/var/app/components/Commons/ChangeLanguageLoading.vue').default,Theme4MessageDialog: require('/var/app/components/Theme4/MessageDialog.vue').default,CommonsThemeMode: require('/var/app/components/Commons/ThemeMode.vue').default})
